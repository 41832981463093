import styles from './ActionButtons.module.css';
import { Plus } from "@phosphor-icons/react";


const ActionButtons = ({setIsOpen}) => {

	return (
		<div className={styles.videoActionsContainer}>
			<div className={styles.btn} onClick={() => setIsOpen(on => !on)}>
				New Video
				<Plus weight="bold" className={styles.icon}/>
			</div>
			<div className={styles.btn}>Photos</div>
		</div>
	);
};

export default ActionButtons;