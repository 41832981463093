import './App.module.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Homepage from './pages/homepage/Homepage';

import VideosPage from './pages/videos/VideosPage';
import VideoPage from './pages/videos/VideoPage';
import PhotosPage from './pages/photos/PhotosPage';
import AboutMePage from './pages/about_me/AboutMePage';
import AdminPage from './pages/admin/AdminPage';
import EditVideoPage from './pages/admin/EditVideoPage';
import Login from './components/Login';
import PageNotFound from './pages/pagenotfound/PageNotFound';
import Navigation from './components/Navigation';
// import ProtectedRoutes from './components/ProtectedRoutes';

import CalculatorPage from './pages/calculator/CalculatorPage';
// const router = createBrowserRouter([
//     { path: '/', element: <Homepage /> },
//     { path: '/movies', element: <MoviesPage />, loader: moviesPageLoader },
//     { path: '/movies/:slug', element: <MoviePage /> },
//     { path: '/commercials', element: <Commercials /> },
//     { path: '/music-videos', element: <MusicVideos /> },
//     { path: '/awards', element: <Awards /> },
//     { path: '/about-me', element: <AboutMe /> },
//     { path: '*', element: <PageNotFound /> }
// ]);

function App() {

    return ( 
        // <RouterProvider router={router}/>

        <Router>
            <Navigation />
            <Routes>
                <Route exact path='/' element={<Homepage />} />

                <Route exact path='/movies/:slug' element={<VideoPage query={'movies'} />} />
                <Route exact path='/movies' element={<VideosPage query={'movies'} key={10}/>} />

                <Route exact path='/commercials/:slug' element={<VideoPage query={'commercials'} />} />
                <Route exact path='/commercials' element={<VideosPage query={'commercials'} key={11}/>} />

                <Route exact path='/music-videos/:slug' element={<VideoPage query={'music-videos'} />} />
                <Route exact path='/music-videos' element={<VideosPage query={'music-videos'} key={12} />} />
                
                <Route exact path='/photos' element={<PhotosPage />} />
                <Route exact path='/about-me' element={<AboutMePage />} />

                {/* <Route element={<ProtectedRoutes />}> */}
                <Route exact path='/mahaga' element={<AdminPage />} />
                <Route exact path='/mahaga/:slug/edit' element={<EditVideoPage />} />
                {/* </Route> */}

                <Route exact path='/login' element={<Login />} />
                <Route exact path='/calculator' element={<CalculatorPage />} />
                
                <Route exact path='*' element={<PageNotFound />} />
            
            </Routes>
            {/*<Routes>*/}
                {/*<Route exact path='/login' element={<Login />} />*/}
            {/*</Routes>*/}
        </Router>

    )
}

export default App