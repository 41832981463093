import styles from './Photos.module.css';
import Photo from './Photo';
import Spinner from '../Spinner';

const Photos = ({photos, isLoading}) => {
	// if(isLoading) return <Spinner />
	return (
		<div className={styles.photosContainer}>
			{photos.map(photo => <Photo photo={photo} key={photo.id}/>)}
		</div>
	);
};

export default Photos;