import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import AdminFinder from '../../apis/AdminFinder';
import styles from './EditVideoPage.module.css';

import Main from '../../components/Main';
import Form from '../../components/admin/Form/Form';
import Input from '../../components/admin/Form/Input';
import Select from '../../components/admin/Form/Select';
import UploadFile from '../../components/admin/Form/UploadFile';
import FormActionButtons from '../../components/admin/Form/FormActionButtons';

const EditVideoPage = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({title: '', link: '', director: '', prod: '', camera: '', color: '', type: ''});

	const submitHandler = async e => {
		e.preventDefault();
		try {
			setIsLoading(true);
			await AdminFinder.put(`/video/${params.slug}`, formData, { 
				headers: { 
					'Content-Type': 'multipart/form-data' 
				}
			});
			setIsLoading(false)
			navigate('/mahaga');
		} catch (err) {
			setIsError(true);
			setIsLoading(false)
			setFormData({title: err.response.data.msg, type: formData.type });
		}		
	};

	useEffect(() => {
		(async () => {
			try {
				const data = await AdminFinder(`/video/${params.slug}`);

				if(!data.data.video) {
					navigate('/mahaga');
					throw new Error('Any video found!');
				}

				setFormData({
					title: data.data.video.title,
					link: data.data.video.link,
					director: data.data.video.director,
					prod: data.data.video.prod, 
					camera: data.data.video.camera, 
					color: data.data.video.color, 
					type: data.data.video.type,
					image: data.data.video.image
				});
			} catch (e) {
				console.log('MESSAGE: ', e.message)
			}
		})();
	}, []);
	
	return (
		<Main>
			<div className={styles.formContainer}>
				<Form onSubmitHandler={submitHandler}>
					<Input label='Title' required={true} value={formData.title} setFormData={setFormData} isError={isError} setIsError={setIsError} />
					<Input label='Link' pattern='http.*' value={formData.link} setFormData={setFormData} />
					<Input label='Director' value={formData.director} setFormData={setFormData} />
					<Input label='Prod' value={formData.prod} setFormData={setFormData} />
					<Input label='Camera' value={formData.camera} setFormData={setFormData} />
					<Input label='Color' value={formData.color} setFormData={setFormData} />

					<Select label='Video Type' value={formData.type} setFormData={setFormData} />
					<UploadFile label='Image' setFormData={setFormData} formData={formData}/>
					<FormActionButtons setFormData={setFormData} isLoading={isLoading}/>
				</Form>
			</div>
		</Main>
	);
};

export default EditVideoPage;