import { useState, useEffect, useRef } from 'react';
import styles from './CalculatorPage.module.css';
import Main from '../../components/Main';
/*

amžiai
20,25,30,35,40,45

svoriai
50,55,60,65,70,75,80,85,90,100

ugiai
160,165,170,175,180,185,190

*/
const bmi = (svoris, ugis, amzius) => {
	const kunoSvoris = 9.463 * svoris;
	const zmogausUgis = 1.8496 * ugis;
	const zmogausAmzius = 4.6756 * amzius;

	return 655.0955 + kunoSvoris + zmogausUgis - zmogausAmzius;
};


const CalculatorPage = () => {
	const [amzius, setAmzius] = useState('');
	const [svoris, setSvoris] = useState('');
	const [ugis, setUgis] = useState('');
	const [ko, setKo] = useState(1);

	const containerRef = useRef('');

	useEffect(() => {
		document.title = 'Calculator';
		const nav = document.querySelector('nav');
		nav.innerHTML = '<p style="color: #fff">Valgau_be_žalos</p>';
	}, []);


	let table = '';
	for(let a of amzius.split(',')) {

		let test = '<td></td>';
		// test = test + '<td></td>';
		svoris.split(',').map(v => {
			test = test + `<td>${v}kg</td>`;
		});

		table = table + `<div class="${styles.tableContainer}"><div>Amžius ${a}</div><table><tr>${test}</tr>`;

		for(let b of ugis.split(',')) {
			table = table + `<tr><td>${b}cm</td>`;

			for(let c of svoris.split(',')) {
				table = table + `<td>${(bmi(c, b, a)*ko).toFixed(2)}</td>`;				
			}
			table = table + `</tr>`;
		}
		table = table + '</table></div>'
	}

	const insert = () => {
		containerRef.current.innerText = '';
		containerRef.current.insertAdjacentHTML("afterbegin", table);
	}

	return (
			<Main>
				<div className={styles.calCon}>
					<div className={styles.calculatorContainer}>
						<div className={styles.amzius}>
							{/*<label htmlFor="amzius">Amžius</label>*/}
							<input id='amzius' onChange={e => setAmzius(e.target.value)} placeholder='Amžius'/>
						</div>
						<div className={styles.svoris}>
							{/*<label htmlFor="svoris">Svoris</label>*/}
							<input id='svoris' onChange={e => setSvoris(e.target.value)} placeholder='Svoris'/>
						</div>
						<div className={styles.ugis}>
							{/*<label htmlFor="ugis">Ūgis</label>*/}
							<input id='ugis' onChange={e => setUgis(e.target.value)} placeholder='Ūgis' />
						</div>
						<select name="" id="coe" onChange={(e) => setKo(Number(e.target.value))}>
							<option value="1">Koeficientas</option>
							<option value="1.25">BMR * 1.25</option>
							<option value="1.375">BMR * 1.375</option>
							<option value="1.55">BMR * 1.55</option>
							<option value="1.725">BMR * 1.725</option>
							<option value="1.9">BMR * 1.9</option>
						</select>
						<div className="calsulate">
							<button onClick={insert} className={styles.skaiciuotiBtn}>Skaičiuoti</button>
						</div>
					</div>
					
					<div ref={containerRef} className={styles.tableResults}></div>
				</div>
			</Main>
	);
}

export default CalculatorPage;